import React from "react";
import "../styles/Shop.scss";

const Shop = () => {
  return (
    <div className="shop">
      <div className="container">
        <div className="presentation">
          <div className="text">Site en construction pour le moment !</div>
          <hr />
          <div className="pub">
            Visitez notre catalogue sur{" "}
            <a href="https://www.instagram.com/maboutike.chogan">
              <span>instagram</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shop;
