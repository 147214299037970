import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  //   createBrowserRouter,
  //   createRoutesFromElements,
} from "react-router-dom";
//import Home from "../components/Home";
import Shop from "../components/Shop";

const RoutesIndex = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/chogan" element={<Shop />} />
        <Route path="*" element={<Navigate to={"/chogan"} />} />
      </Routes>
    </Router>
  );
};

export default RoutesIndex;
